<template>
  <div class="contents">
    <div class="col-lg-12">
      <div class="breadcrumb-main">
        <h4 class="text-capitalize breadcrumb-title">
          Class
          <ul class="atbd-breadcrumb nav">
            <li class="atbd-breadcrumb__item">
              <a href="#">
                <span class="la la-home"></span>
              </a>
              <span class="breadcrumb__seperator">
                <span class="la la-slash"></span>
              </span>
            </li>
            <li class="atbd-breadcrumb__item">
              <a href="#">Metrics</a>
              <span class="breadcrumb__seperator">
                <span class="la la-slash"></span>
              </span>
            </li>
            <li class="atbd-breadcrumb__item">
              <span>Classes</span>
            </li>
          </ul>
        </h4>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="contact-list-wrap mb-25">
            <div class="contact-list bg-white radius-xl w-100">
              <div class="mb-2">
                <h3><small> Total Classes </small> {{ classGroupCount }}</h3>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-borderless table-rounded">
                  <thead>
                    <tr class="">
                      <th scope="col">
                        <span class="userDatatable-title">Name</span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title">Total Subjects</span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title">Total Topics </span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title float-right"
                          >Actions</span
                        >
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(p, k) in classGroup" :key="k" @click="gotoClass(classMap[k])" class="pointer">
                      <td>
                        <div class="contact-item d-flex align-items-center">
                          {{ classMap[k].nick }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="orderDatatable-title">
                            <p class="d-block mb-0">
                              {{ p.length }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="orderDatatable-title">
                            <p class="d-block mb-0">
                              {{ getTopicsTotal(p) }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ul
                          class="
                            orderDatatable_actions
                            mb-0
                            d-flex
                            flex-wrap
                            float-right
                          "
                        >
                          <li>
                            <a href="#"
                              class="remove"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="view"
                            >
                              <i class="las la-eye"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- ends: col-12 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState([
      "asyncLoader",
      "primary",
      "junior",
      "senior",
      "primarySub",
      "juniorSub",
      "seniorSub",
      "classes",
      "classMap",
      "topics",
      "subjects",
      "stat",
    ]),
    classGroup() {
      const group = {};
      const combineClasses = [
        ...this.primarySub,
        ...this.juniorSub,
        ...this.seniorSub,
      ];
      combineClasses.forEach((i) => {
        if (group[i.cllid]) {
          group[i.cllid] = group[i.cllid].concat(i);
        } else {
          group[i.cllid] = [i];
        }
      });
      return group;
    },
    classGroupCount() {
      return Object.keys(this.classGroup).length;
    },
  },
  methods: {
    getTopicsTotal(subList) {
      return subList.map((s) => s.total_topic).reduce((c, a) => c + a);
    },
    gotoClass(c) {
      const classCategory = c.category.toLowerCase();
      let name = "";
      if (classCategory == "primary") {
        name = "classes-primary";
      } else if (classCategory == "junior") {
        name = "classes-junior";
      } else {
        name = "classes-senior";
      }
      this.$router.push({ name, params: { classParam: c } });
    },
  },
};
</script>

<style>
</style>